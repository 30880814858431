import { CabIcon } from "@CabComponents/CabIcon";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { ReactElement } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";

export interface InfoToolTipProps {
  message: string;
}

const InfoToolTip = ({message}: InfoToolTipProps): ReactElement => {
  return (
    <CabTooltip
      title={message}
      placement="bottom"
      wrapWithSpan
    >
      <CabIcon Icon={IoInformationCircleOutline} color='secondary' sx={{fontSize: 20}}/>
    </CabTooltip>
  );
};

export default InfoToolTip;