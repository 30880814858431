import { memo, ReactElement, useState } from "react";
import { FormControl, FormControlProps, styled } from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import { CabDropdown } from "@CabComponents/CabDropdown";
import { MEETING_DURATION_UNITS } from "../../../constants";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { useMountEffect } from "../../../utils/hooks";

export interface ICabDurationUnitPicker extends CabComponentProps<FormControlProps>  {
  sx?: FormControlProps['sx'];
  durationMinutes: number;
  onChange: (newDuration: number) => void;
  disabled?: boolean;
  textSize?: boolean;
}

const CabDurationUnitPickerComponent = ({
  overrides, sx, durationMinutes, onChange, disabled, textSize
}: ICabDurationUnitPicker): ReactElement => {
  const [duration, setDuration] = useState<number>(0);
  const [unit, setUnit] = useState<keyof typeof MEETING_DURATION_UNITS> ('MINUTES');
  
  const props: Omit<FormControlProps, 'children'> = {
    sx,
  };

  useMountEffect(() => {
    if (durationMinutes > 0 && durationMinutes % 1440 === 0) {
      setDuration(durationMinutes / 1440);
      setUnit('DAYS');
    } else if (durationMinutes > 0 && durationMinutes % 60 === 0) {
      setDuration(durationMinutes / 60);
      setUnit('HOURS');
    } else {
      setDuration(durationMinutes);
      setUnit('MINUTES');
    }
  });

  const handleChangeDuration = (value: number) => {
    if (value >= 0) {
      setDuration(value);
      const multiplier = unit === 'DAYS' ? 1440 : unit === 'HOURS' ? 60 : 1;
      const newDurationMinutes = multiplier * value;
      if (newDurationMinutes > 142560) { // max integer allowed in database
        onChange(142560);
      } else {
        onChange(newDurationMinutes);
      }
    }
  };

  const handleChangeUnit = (value: keyof typeof MEETING_DURATION_UNITS) => {
    setUnit(value);
    const multiplier = value === 'DAYS' ? 1440 : value === 'HOURS' ? 60 : 1;
    const newDurationMinutes = multiplier * duration;
    if (newDurationMinutes > 142560) { // max integer allowed in database
      onChange(142560);
    } else {
      onChange(newDurationMinutes);
    }
  };
  
  return (
    <FormControl {...props} {...overrides}>
      <MainContainer>
        <InputContainer>
          <CabTextInput
            size={textSize ? 'text' : 'xsmall'}
            value={duration.toString()}
            type='number'
            disabled={disabled}
            InputProps={{ inputProps: { min: 0 } }}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              handleChangeDuration(Number(e.target.value));
            }}
            sx={{
              width: 50,
              '& .MuiOutlinedInput-input':{
                paddingRight: 0.75,
                paddingLeft: 0.75,
                textAlign: 'center'
              },
            }}
          />
        </InputContainer>
        <InputContainer>
          <CabDropdown<keyof typeof MEETING_DURATION_UNITS>
            size={textSize ? 'xsmall' : 'small'}
            hideArrow
            disabled={disabled}
            value={unit}
            onChange={(e) => handleChangeUnit(e.target.value as keyof typeof MEETING_DURATION_UNITS)}
            options={Object.keys(MEETING_DURATION_UNITS).map((unitItr) => ({
              value: unitItr as keyof typeof MEETING_DURATION_UNITS,
              label: MEETING_DURATION_UNITS[unitItr as keyof typeof MEETING_DURATION_UNITS],
            }))}
            sx={{ width: '85px',
              '& .MuiOutlinedInput-input':{
                paddingRight: '14px !important'
              },
            }}
          />
        </InputContainer>
      </MainContainer>
    </FormControl>
  );
};

const InputContainer = styled('div', {
  label: 'InputContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
}));

const MainContainer = styled('div', {
  label: 'MainContainer',
})(({ theme }) => ({
  display: 'flex',
  gap: 4,
}));

export const CabDurationUnitPicker = memo(CabDurationUnitPickerComponent, (prev, next) => {
  return prev.durationMinutes === next.durationMinutes && prev.disabled === next.disabled;
}); 